import { Injectable, isDevMode } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class LoggingService {

  logError(error: Error) {
    if (isDevMode) {
      console.error(error);
    }
  }
}
