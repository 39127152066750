import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class GlobalService {

  private userInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  private companyOid: string;
  private companyType: string;
  private toggleValue: string;

  getUserInfo(): Observable<any> {
    return this.userInfo$.asObservable();
  }

  setUserInfo(info: any) {
    this.userInfo$.next(Object.assign({}, info));
  }

  getCompanyOid() {
    return this.companyOid;
  }

  setCompanyOid(oid: string) {
    this.companyOid = oid;
  }

  getCompanyType() {
    return this.companyType;
  }

  setCompanyType(value: string) {
    this.companyType = value;
  }

  setToggleValue(value: string) {
    this.toggleValue = value;
  }

  getToggleValue() {
    return this.toggleValue;
  }

  setInitialState() {
    this.setCompanyOid(null);
    this.setCompanyType(null);
    this.setUserInfo(null);
    this.setToggleValue(null);
  }

}
