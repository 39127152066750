import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { PATH } from "@app/utility/constant/path";
import { Observable } from "rxjs/internal/Observable";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }
  canActivate(): Observable<boolean> | boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }
    this.router.navigate([PATH.LOGIN]);
    return false;
  }
}
