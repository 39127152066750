export class AuthInfo {
  loginId: string;
  roleId: string;
  userName: string;
  companyType: string;
  prefix: string;
  companyOid: string;
  resetRequired: string;
}


export class CompanyJson {
  baseDirectory: string;
  contextPath: string;
  schemaName: string;
}
