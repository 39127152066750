import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guard";
import { AppCustomPreloader } from "./core/services/app-custom-preloader";

const routes: Routes = [
  {
    path: "signin",
    loadChildren: () => import("./auth/login/login.module").then(m => m.LoginModule)
  },
  {
    path: "forgot-password",
    loadChildren: () => import("./security/forgot-password/forgot-password.module").then(m => m.ForgotPasswordModule)
  },
  {
    path: "form1",
    loadChildren: () => import("./web/create-agent-appli/create-agent-appli.module").then(mod => mod.CreateAgentAppliModule)
  },
  {
    path: "session-expired",
    loadChildren: () => import("./auth/session-expired/session-expired.module").then(mod => mod.SessionExpiredModule)
  },
  {
    path: "",
    loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "", pathMatch: "full" }
];

const routeConfig: ExtraOptions = {
  preloadingStrategy: AppCustomPreloader,
  relativeLinkResolution: "legacy"
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routeConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
