import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { PATH } from "@app/utility/constant/path";
import { AppStorageService } from "@app/utility/services/app.storage.service";

@Component({
  selector: "app-snackbar-session-timeout",
  template: `<div class="flex">
                    <div class="modal-header">
                        <h4 class="modal-title text-danger">{{ title }}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <p class="p-message">Your last login was at : <span class="text-muted">{{ message }}</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button mat-icon-button (click)="onCancelClick()" style="float: right">
                            <mat-icon>OK</mat-icon>
                        </button>
                    </div>
                </div>`,
})
export class SessionTimeOutSnackBarComponent {

  message: string;
  title: string;
  constructor(private router: Router, private appStorageService: AppStorageService,
                public snackBarRef: MatSnackBarRef<SessionTimeOutSnackBarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.message = data.message;
    this.title = data.title;
  }

  onCancelClick(): void {
    this.snackBarRef.dismiss();
    this.appStorageService.clear();
    this.router.navigate([PATH.LOGIN]);
  }

}
