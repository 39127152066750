import { environment } from "src/environments/environment";
export class APIConfig {
  static resourceServerUrl = environment.baseUrl;
  static reportServerUrl = environment.reportUrl;
}

export class API {
  static SIGNIN = "/v1/user/signin";
  static SIGNOUT = "/v1/user/signout";
  static CHANGE_PASSWORD = "/v1/login/password";
  static FORGOT_PASSWORD = "/v1/login/password/forgot";
  static VERIFY_ONE_TIME_PASSWORD = "/v1/login/password/verify-otp";
  static RESET_USER_PASSWORD = "/v1/user/reset-password";
  static RESET_COMPANY_PASSWORD = "/v1/login/reset-company-password";
  static CHECK_USER_ID_AVAILABILITY = "/v1/user/check-availability/userid";
  static CREATE_USER = "/v1/user/create-user";
  static INACTIVE_USER_BY_USER_ID = "/v1/agent/application/user/inactive";
  static CREATE_COMPANY_BRANCH_USER = "/v1/user/create-branch-user";
  static COMPANY_BRANCH_USER_GET_LIST = "/v1/user/branch-user/get-all-list";

  static USER_PROFILE_INFO = "/v1/user/profile/info";

  static AGENT_APPLICATION_DOWNLOAD_PATH = "/v1/agent/application/download";
  static AGENT_APPLICATION_UPLOAD_PATH = "/v1/agent/application/upload";
  static AGENT_APPLICATION_DELETE_PATH = "/v1/agent/application/delete";
  static REJECTION_REASON_LIST = "/v1/rejection-reason/get-list";
  static AGENT_APPLICATION_ROLE_GET_ALL_PATH =
    "/v1/agent/application/role/get-all-list";
  static INSURER_INFORMATION_DOWNLOAD_PATH =
    "/v1/agent/application/download/insurer";
  static TOTAL_AGENT_SUMMARY = "/v1/report/module/agent/summary";
  static TOTAL_AGENT_SUMMARY_TREE_VIEW =
    "/v1/report/module/agent/summary/tree-view";
  static TOTAL_AGENT_SUMMARY_COMPANY_WISE =
    "/v1/report/module/agent/summary/company";

  // -------------------------------- LIFE --------------------------------

  static AGENT_ACCESS_NUMBER_GEN_PATH =
    "/v1/agent/application/gen-access-number";
  static AGENT_ACCESS_NUMBER_VERY_PATH =
    "/v1/agent/application/verify-access-number";
  static AGENT_ACCESS_NUMBER_GET_LIST = "/v1/agent/application/get-list";

  static AGENT_APPLICATION_LICENCE_GET_RENEWAL_LIST_PATH =
    "/v1/agent/application/licence/get-renewal-list";
  static AGENT_APPLICATION_LICENCE_GET_LIST_PATH =
    "/v1/agent/application/licence/get-list";
  static AGENT_APPLICATION_LICENCE_GET_BY_OID_PATH =
    "/v1/agent/application/licence/get-by-oid";
  static AGENT_APPLICATION_LICENCE_GET_DISTRIBUTE_LIST_PATH =
    "/v1/agent/application/licence/get-distribute-list";
  static AGENT_APPLICATION_LICENCE_DISTRIBUTE_PATH =
    "/v1/agent/application/licence/distribute";

  static AGENT_APPLICATION_TYPE_PATH = "/v1/agent/application/get-type-list";

  static AGENT_APPLICATION_BATCH_RESOURCES = "/v1/agent/application/batch";
  static AGENT_APPLICATION_BATCH_GET_BY_OID_PATH =
    "/v1/agent/application/batch/get-by-oid";
  static AGENT_APPLICATION_BATCH_GET_ALL_PATH =
    "/v1/agent/application/batch/get-all-list";
  static AGENT_APPLICATION_BATCH_SAVE_PATH = "/v1/agent/application/batch/save";
  static AGENT_APPLICATION_BATCH_DISCARD_PATH =
    "/v1/agent/application/batch/discard";
  static AGENT_APPLICATION_BATCH_UPDATE_PATH =
    "/v1/agent/application/batch/update";
  static AGENT_APPLICATION_BATCH_GET_LOCK_PATH =
    "/v1/agent/application/batch/lock";
  static AGENT_APPLICATION_BATCH_GET_UNLOCK_PATH =
    "/v1/agent/application/batch/unlock";
  static AGENT_APPLICATION_BATCH_GET_BY_USER_PATH =
    "/v1/agent/application/batch/get-list-by-user";
  static AGENT_APPLICATION_BATCH_UPDATE_ACK_REF_PATH =
    "/v1/agent/application/batch/update-ack-reference";
  static AGENT_APPLICATION_BATCH_GET_APPROVED_LIST_PATH =
    "/v1/agent/application/batch/get-approve-list";
  static AGENT_APPLICATION_BATCH_REPORT_DOWNLOAD_PATH =
    "/v1/agent/application/life/batch/get-report/download";

  static AGENT_APPLICATION_LETTER_APPROVED =
    "/v1/agent/application/employee/letter";
  static AGENT_APPLICATION_LIFE_LETTER_BY_OID_PATH =
    "/v1/agent/application/employee/letter/get-by-oid";

  static AGENT_APPLICATION_TMP_GET_LIST_PATH =
    "/v1/agent/application/life/1/get-list";

  static LIFE_AGENT_APPLICATION_GET_BY_OID =
    "/v1/agent/application/life/1/get-by-oid";
  static LIFE_AGENT_UPDATE_INFO_GET_BY_OID =
    "/v1/agent/update-info/life/1/get-by-oid";
  static LIFE_AGENT_LICENSE_INFO_UPDATE =
    "/v1/agent/license/life/update-licence-info";

  static AGENT_APPLICATION_TMP_LOCK = "/v1/agent/application/life/1/lock";
  static AGENT_APPLICATION_TMP_UNLOCK = "/v1/agent/application/life/1/unlock";
  static AGENT_APPLICATION_TMP_DELETE = "/v1/agent/application/life/1/delete";
  static AGENT_APPLICATION_TMP_UPDATE = "/v1/agent/application/life/1/update";
  static AGENT_TMP_LETTER_INFO_UPDATE =
    "/v1/agent/application/life/update-letter-info";

  static AGENT_APPLICATION_TMP_SAVE = "/v1/agent/application/life/1/save";
  static AGENT_APPLICATION_TMP_APPROVE_PATH =
    "/v1/agent/application/life/1/approve";
  static AGENT_APPLICATION_TMP_GET_REGISTRY_LIST_PATH =
    "/v1/agent/application/life/1/get-registry-list";
  static AGENT_APPLICATION_GET_BY_OID_PATH =
    "/v1/agent/application/life/1/get-agent-appli-by-oid";
  static AGENT_APPLICATION_TMP_AGENT_GET_LICENSE_LIST =
    "/v1/agent/application/life/get-renewal-list";

  static AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER =
    "/v1/agent/application/life/release-letter";
  static AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER_GET_LIST =
    "/v1/agent/application/life/release-letter/get-list";
  static LIFE_AGENT_INFO = "/v1/agent/application/life/get-agent-info";
  static USER_INFO_BY_USER_ID =
    "/v1/agent/application/get-agent-info-by-userId";
  static AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER_GET_BY_OID =
    "/v1/agent/application/life/release-letter/get-by-oid";

  static NON_LIFE_AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER =
    "/v1/agent/application/non-life/release-letter";
  static NON_LIFE_AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER_GET_LIST =
    "/v1/agent/application/non-life/release-letter/get-list";
  static NON_LIFE_AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER_GET_BY_OID =
    "/v1/agent/application/non-life/release-letter/get-by-oid";

  static AGENT_APPLICATION_LICENCE_LINK_PATH =
    "/v1/agent/application/life/3/link";
  static AGENT_APPLICATION_LICENCE_UNLINK_PATH =
    "/v1/agent/application/life/3/unlink";
  static AGENT_APPLICATION_LICENCE_SAVE_EDIT_PATH =
    "/v1/agent/application/life/3/save-edit";
  static AGENT_APPLICATION_LICENCE_SAVE_PATH =
    "/v1/agent/application/life/3/save";
  static AGENT_APPLICATION_LICENCE_UPDATE_PATH =
    "/v1/agent/application/life/3/update";
  static AGENT_APPLICATION_LICENCE_VERIFY_CODE_PATH =
    "/v1/agent/application/life/3/verify-agent-code";
  static AGENT_APPLICATION_LICENCE_APPROVE_PATH =
    "/v1/agent/application/life/3/approve";
  static AGENT_APPLICATION_FORM3_DELETE_PATH =
    "/v1/agent/application/life/3/delete";
  static AGENT_APPLICATION_LICENCE_AUTO_DRAFT_SAVE_UPDATE_PATH =
    "/v1/agent/application/life/3/draft/save-update";

  static AGENT_APPLICATION_RENEW_LICENCE_VERIFY_CODE_PATH =
    "/v1/agent/application/renew/life/5/verify-agent-code";
  static AGENT_APPLICATION_RENEW_LICENCE_SAVE_PATH =
    "/v1/agent/application/renew/life/5/save";
  static AGENT_APPLICATION_RENEW_LICENCE_SAVE_EDIT_PATH =
    "/v1/agent/application/renew/life/5/save-edit";
  static AGENT_APPLICATION_RENEW_LICENCE_APPROVE_PATH =
    "/v1/agent/application/renew/life/5/approve";
  static AGENT_APPLICATION_RENEW_LICENCE_AUTO_DRAFT_SAVE_UPDATE_PATH =
    "/v1/agent/application/renew/life/5/draft/save-update";

  static LIFE_AGENT_APPLICATION_GET_LIST_BY_COMPANY_PATH =
    "/v1/agent/application/life";
  static LIFE_AGENT_APPLICATION_GET_BY_OID_PATH =
    "/v1/agent/application/life/get-by-oid";
  static LIFE_AGENT_APPLICATION_GET_APPROVED_OR_REJECTED_LIST =
    "/v1/agent/application/life/get-approved-or-rejected-list";
  static LIFE_AGENT_APPLICATION_GET_REJECTED_LIST =
    "/v1/agent/application/life/get-rejected-list";
  static AGENT_APPLICATION_LICENCE_POLICY_REPORT_GET_BY_AGENT_CODE =
    "/v1/agent/application/life/3/agent-polcy-report/get-by-agent-code";
  static AGENT_APPLICATION_RENEW_LICENCE_POLICY_REPORT_GET_BY_AGENT_CODE =
    "/v1/agent/application/life/5/agent-polcy-report/get-by-agent-code";
  static AGENT_APPLICATION_GET_AGENTCODE_BY_BATCH_OID_PATH =
    "/v1/agent/application/life/agentcode/get-by-batch-oid";

  static AGENT_APPLICATION_COMPANY_RESOURCES =
    "/v1/agent/application/company/get-list";

  static AGENT_APPLICATION_LIFE_REGISTER_GET_PATH =
    "/v1/agent/application/life/agent/register/get-list";
  static AGENT_APPLICATION_LIFE_REGISTER_CSV_DOWNLOAD_PATH =
    "/v1/agent/application/life/agent/register/get-csv";

  static LIFE_TRAINING_REGISTER_XLS_DOWNLOAD =
    "/v1/agent/application/training/institute/life/register/download";
  static NON_LIFE_TRAINING_REGISTER_XLS_DOWNLOAD =
    "/v1/agent/application/training/institute/non-life/register/download";

  static AGENT_ACCESS_NUMBER_VERY_PUBLIC_PATH =
    "/public/v1/agent/application/verify-access-number";
  static AGENT_APPLICATION_UPLOAD_PUBLIC_PATH =
    "/public/v1/agent/application/upload";
  static AGENT_APPLICATION_DELETE_PUBLIC_PATH =
    "/public/v1/agent/application/delete";
  static AGENT_APPLICATION_DOWNLOAD_PUBLIC_PATH =
    "/public/v1/agent/application/download";
  static AGENT_APPLICATION_TMP_PUBLIC_SAVE_PATH =
    "/public/v1/agent/application/life/1/save";

  static RESET_COMPANY_PASSWORD_BY_COMPANY_REVIEWER =
    "/v1/login/reset/password/by-company-reviewer";

  static LETTER_REPORT = "/v1/agent/application/employee/letter/2/pdf";
  static LICENCE_REPORT = "/v1/agent/application/licence/3/pdf";
  static LICENCE_RENEW_REPORT = "/v1/agent/application/licence/5/pdf";

  static MESSAGE_TEMPLATE_GET_LIST = "/v1/message-template/get-list";
  static MESSAGE_PROVIDER_GET_LIST = "/v1/message-provider/get-list";

  static SMS_SUMMARY_GET_LIST = "/v1/agent/application/sms-summary/get-list";
  static DOWNLOAD_SMS_SUMMARY = "/v1/agent/application/sms-summary/download";
  static SMS_HISTORY_GET_LIST = "/v1/agent/application/sms-history/get-list";

  static COMPANY_SMS_SUMMARY_REPORT_GET_LIST =
    "/v1/agent/application/sms-report/get-list";
  static COMPANY_SMS_SUMMARY_SENT_REPORT_GET_LIST =
    "/v1/agent/application/sms-report/sent/get-list";
  static COMPANY_SMS_SUMMARY_FAILED_REPORT_GET_LIST =
    "/v1/agent/application/sms-report/failed/get-list";
  static DOWNLOAD_SMS_REPORT = "/v1/agent/application/sms-report/download";

  static PUBLIC_LETTER_REPORT = "/public/2";
  static PUBLIC_LICENCE_REPORT = "/public/5";
  static PUBLIC_LICENCE_RENEW_REPORT = "/public/7";

  static LIFE_AGENT_TRAINING_INST_DOWNLOADED_APPLICATION_LIST =
    "/v1/agent/application/life/training/application/download/get-list";
  static LIFE_AGENT_TRAINING_INST_DOWNLOADED_PREVIOUS_FILE =
    "/v1/agent/application/life/training/application/download/by-filename";

  static LIFE_AGENT_HISTORY_BY_AGENT_CODE_PATH =
    "/v1/agent/application/life/agent-history/get-by-agent-code";

  // -------------------------------- NON-LIFE --------------------------------

  static NON_LIFE_AGENT_APPLICATION_TRAINING_HIST_GET_PATH =
    "/v1/agent/application/non-life/training/history/get-list";

  static NON_LIFE_AGENT_APPLICATION_TMP_APPROVE_PATH =
    "/v1/agent/application/non-life/1/approve";
  static NON_LIFE_AGENT_APPLICATION_TMP_SAVE =
    "/v1/agent/application/non-life/1/save";
  static NON_LIFE_AGENT_APPLICATION_TMP_GET_LIST_PATH =
    "/v1/agent/application/non-life/1/get-list";
  static NON_LIFE_AGENT_APPLICATION_GET_BY_OID =
    "/v1/agent/application/non-life/1/get-by-oid";
  static NON_LIFE_AGENT_APPLICATION_TMP_UPDATE =
    "/v1/agent/application/non-life/1/update";
  static NON_LIFE_AGENT_TMP_LETTER_INFO_UPDATE =
    "/v1/agent/application/non-life/update-letter-info";
  static NON_LIFE_AGENT_APPLICATION_TMP_DELETE =
    "/v1/agent/application/non-life/1/delete";
  static NON_LIFE_AGENT_APPLICATION_TMP_LOCK =
    "/v1/agent/application/non-life/1/lock";
  static NON_LIFE_AGENT_APPLICATION_TMP_UNLOCK =
    "/v1/agent/application/non-life/1/unlock";
  static NON_LIFE_AGENT_APPLICATION_GET_AGENT_APPLI_BY_OID_PATH =
    "/v1/agent/application/non-life/1/get-agent-appli-by-oid";
  static NON_LIFE_AGENT_APPLICATION_TMP_GET_REGISTRY_LIST_PATH =
    "/v1/agent/application/non-life/1/get-registry-list";

  static NON_LIFE_AGENT_UPDATE_INFO_GET_BY_OID =
    "/v1/agent/update-info/non-life/1/get-by-oid";
  static NON_LIFE_AGENT_APPLICATION_TMP_AGENT_GET_LICENSE_LIST =
    "/v1/agent/application/non-life/get-renewal-list";

  static NON_LIFE_AGENT_APPLICATION_GET_LIST_BY_COMPANY_PATH =
    "/v1/agent/application/non-life";
  static NON_LIFE_AGENT_APPLICATION_GET_BY_OID_PATH =
    "/v1/agent/application/non-life/get-by-oid";
  static NON_LIFE_AGENT_APPLICATION_GET_APPROVED_OR_REJECTED_LIST =
    "/v1/agent/application/non-life/get-approved-or-rejected-list";
  static NON_LIFE_AGENT_APPLICATION_GET_REJECTED_LIST =
    "/v1/agent/application/non-life/get-rejected-list";

  static NON_LIFE_AGENT_APPLICATION_LETTER_APPROVED =
    "/v1/agent/application/non-life/employee/letter";
  static NON_LIFE_AGENT_APPLICATION_LETTER_BY_OID_PATH =
    "/v1/agent/application/non-life/employee/letter/get-by-oid";

  static NON_LIFE_AGENT_APPLICATION_LICENCE_GET_LIST_PATH =
    "/v1/agent/application/non-life/licence/get-list";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_GET_RENEWAL_LIST_PATH =
    "/v1/agent/application/non-life/licence/get-renewal-list";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_DISTRIBUTE_PATH =
    "/v1/agent/application/non-life/licence/distribute";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_GET_BY_OID_PATH =
    "/v1/agent/application/non-life/licence/get-by-oid";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_GET_DISTRIBUTE_LIST_PATH =
    "/v1/agent/application/non-life/licence/get-distribute-list";

  static NON_LIFE_AGENT_APPLICATION_LICENCE_VERIFY_CODE_PATH =
    "/v1/agent/application/non-life/3/verify-agent-code";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_SAVE_PATH =
    "/v1/agent/application/non-life/3/save";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_SAVE_EDIT_PATH =
    "/v1/agent/application/non-life/3/save-edit";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_LINK_PATH =
    "/v1/agent/application/non-life/3/link";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_UNLINK_PATH =
    "/v1/agent/application/non-life/3/unlink";
  static NON_LIFE_AGENT_APPLICATION_FORM3_DELETE_PATH =
    "/v1/agent/application/non-life/3/delete";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_APPROVE_PATH =
    "/v1/agent/application/non-life/3/approve";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_UPDATE_PATH =
    "/v1/agent/application/non-life/3/update";
  static NON_LIFE_AGENT_LICENSE_INFO_UPDATE =
    "/v1/agent/license/non-life/update-licence-info";
  static NON_LIFE_AGENT_APPLICATION_LICENCE_AUTO_DRAFT_SAVE_UPDATE_PATH =
    "/v1/agent/application/non-life/3/draft/save-update";

  static NON_LIFE_AGENT_APPLICATION_BATCH_RESOURCES =
    "/v1/agent/application/non-life/batch";
  static NON_LIFE_AGENT_APPLICATION_BATCH_DISCARD_PATH =
    "/v1/agent/application/non-life/batch/discard";
  static NON_LIFE_AGENT_APPLICATION_BATCH_GET_ALL_PATH =
    "/v1/agent/application/non-life/batch/get-all-list";
  static NON_LIFE_AGENT_APPLICATION_BATCH_GET_BY_OID_PATH =
    "/v1/agent/application/non-life/batch/get-by-oid";
  static NON_LIFE_AGENT_APPLICATION_BATCH_SAVE_PATH =
    "/v1/agent/application/non-life/batch/save";
  static NON_LIFE_AGENT_APPLICATION_BATCH_UPDATE_PATH =
    "/v1/agent/application/non-life/batch/update";
  static NON_LIFE_AGENT_APPLICATION_BATCH_GET_BY_USER_PATH =
    "/v1/agent/application/non-life/batch/get-list-by-user";
  static NON_LIFE_AGENT_APPLICATION_BATCH_GET_APPROVED_LIST_PATH =
    "/v1/agent/application/non-life/batch/get-approve-list";
  static NON_LIFE_AGENT_APPLICATION_BATCH_REPORT_DOWNLOAD_PATH =
    "/v1/agent/application/non-life/batch/get-report/download";

  static NON_LIFE_AGENT_APPLICATION_RENEW_LICENCE_VERIFY_CODE_PATH =
    "/v1/agent/application/renew/non-life/5/verify-agent-code";
  static NON_LIFE_AGENT_APPLICATION_RENEW_LICENCE_SAVE_PATH =
    "/v1/agent/application/renew/non-life/5/save";
  static NON_LIFE_AGENT_APPLICATION_RENEW_LICENCE_SAVE_EDIT_PATH =
    "/v1/agent/application/renew/non-life/5/save-edit";
  static NON_LIFE_AGENT_APPLICATION_RENEW_LICENCE_APPROVE_PATH =
    "/v1/agent/application/renew/non-life/5/approve";
  static NON_LIFE_AGENT_APPLICATION_RENEW_LICENCE_AUTO_DRAFT_SAVE_UPDATE_PATH =
    "/v1/agent/application/renew/non-life/5/draft/save-update";

  static NON_LIFE_AGENT_APPLICATION_REGISTER_CSV_DOWNLOAD_PATH =
    "/v1/agent/application/non-life/agent/register/get-csv";
  static NON_LIFE_AGENT_APPLICATION_REGISTER_GET_PATH =
    "/v1/agent/application/non-life/agent/register/get-list";
  static NON_LIFE_AGENT_INFO = "/v1/agent/application/non-life/get-agent-info";
  static NON_LIFE_AGENT_APPLICATION_TMP_PUBLIC_SAVE_PATH =
    "/public/v1/agent/application/non-life/1/save";

  static NON_LIFE_AGENT_TRAINING_INST_DOWNLOADED_APPLICATION_LIST =
    "/v1/agent/application/non-life/training/application/download/get-list";
  static NON_LIFE_AGENT_TRAINING_INST_DOWNLOADED_PREVIOUS_FILE =
    "/v1/agent/application/non-life/training/application/download/by-filename";

  static NON_LIFE_AGENT_HISTORY_BY_AGENT_CODE_PATH =
    "/v1/agent/application/non-life/agent-history/get-by-agent-code";

  // -------------------------------- IDRA Life Bulk-Approve --------------------------------

  static AGENT_APPLICATION_LICENCE_UPDATE_MULTIPLE_PATH =
    "/v1/agent/application/life/3/multiple/update";
  static AGENT_APPLICATION_LICENCE_APPROVE_MULTIPLE_PATH =
    "/v1/agent/application/life/3/multiple/approve";
  static AGENT_APPLICATION_RENEW_LICENCE_APPROVE_MULTIPLE_PATH =
    "/v1/agent/application/renew/life/5/multiple/approve";

  // -------------------------------- IDRA Non-Life Bulk-Approve --------------------------------

  static NON_LIFE_AGENT_APPLICATION_MULTIPLE_LICENCE_UPDATE_PATH =
    "/v1/agent/application/non-life/3/multiple/update";
  static NON_LIFE_AGENT_APPLICATION_MULTIPLE_LICENCE_APPROVE_PATH =
    "/v1/agent/application/non-life/3/multiple/approve";
  static NON_LIFE_AGENT_APPLICATION_MULTIPLE_RENEW_LICENCE_APPROVE_PATH =
    "/v1/agent/application/renew/non-life/5/multiple/approve";

  // -------------------------------- ALO COMMON --------------------------------

  static AGENT_ACCESS_NUMBER_GET_LIST_ALO =
    "/v1/agent/application/alo/get-list";
  static GET_USER_LIST_ALO = "/v1/agent/application/alo/get-user-list";
  static USER_DETAIL_GET_BY_OID_ALO =
    "/v1/agent/application/alo/get-user-by-oid";

  // -------------------------------- ALO LIFE --------------------------------

  static LIFE_AGENT_LATE_FEES_CALCULATOR =
    "/v1/agent/application/life/agent/register/late-fees-calculator";
  static AGENT_APPLICATION_LETTER_APPROVED_ALO =
    "/v1/agent/application/alo/employee/letter";
  static AGENT_APPLICATION_TMP_GET_LIST_PATH_ALO =
    "/v1/agent/application/alo/life/1/get-list";
  static AGENT_APPLICATION_TMP_AGENT_GET_LICENSE_LIST_ALO =
    "/v1/agent/application/alo/life/get-renewal-list";
  static AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER_GET_LIST_ALO =
    "/v1/agent/application/alo/life/release-letter/get-list";
  static AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER_GET_BY_OID_ALO =
    "/v1/agent/application/alo/life/release-letter/get-by-oid";

  // -------------------------------- ALO NON-LIFE --------------------------------

  static NON_LIFE_AGENT_LATE_FEES_CALCULATOR =
    "/v1/agent/application/non-life/agent/register/late-fees-calculator";
  static NON_LIFE_AGENT_APPLICATION_LETTER_APPROVED_ALO =
    "/v1/agent/application/alo/non-life/employee/letter";
  static NON_LIFE_AGENT_APPLICATION_TMP_GET_LIST_PATH_ALO =
    "/v1/agent/application/alo/non-life/1/get-list";
  static NON_LIFE_AGENT_APPLICATION_TMP_AGENT_GET_LICENSE_LIST_ALO =
    "/v1/agent/application/alo/non-life/get-renewal-list";
  static NON_LIFE_AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER_GET_LIST_ALO =
    "/v1/agent/application/alo/non-life/release-letter/get-list";
  static NON_LIFE_AGENT_APPLICATION_TMP_AGENT_RELEASE_LETTER_GET_BY_OID_ALO =
    "/v1/agent/application/alo/non-life/release-letter/get-by-oid";

  static NON_LIFE_LETTER_REPORT =
    "/v1/agent/application/non-life/employee/letter/2/pdf";
  static NON_LIFE_LICENCE_REPORT =
    "/v1/agent/application/non-life/licence/3/pdf";
  static NON_LIFE_LICENCE_RENEW_REPORT =
    "/v1/agent/application/non-life/licence/5/pdf";

  static NON_LIFE_PUBLIC_LETTER_REPORT = "/public/non-life/2";
  static NON_LIFE_PUBLIC_LICENCE_REPORT = "/public/non-life/5";
  static NON_LIFE_PUBLIC_LICENCE_RENEW_REPORT = "/public/non-life/7";

  static NOTIFICATION_GET_LIST = "/v1/notification/get-list";

  // ----------------------------- Training Institute Portal (Life) -------------------------------

  static LIFE_AGENT_TRAINING_INST_APPLICANT_LIST =
    "/v1/agent/application/life/training/applicant/get-list";
  static AGENT_APPLICATION_TRAINING_INS_GET_ALL_PATH =
    "/v1/agent/application/training/institute/get-all-list";
  static AGENT_APPLICATION_LIFE_TRAINING_HIST_GET_PATH =
    "/v1/agent/application/life/training/history/get-list";
  static SENT_LICENSE_CANDIDATES_FOR_TRAINING_PATH =
    "/v1/agent/application/life/training/training-application";
  static LIFE_AGENT_TRAINING_INST_APPLICATION_LIST =
    "/v1/agent/application/life/training/application/get-list";
  static LIFE_TRAINING_APPLICATION_LIST =
    "/v1/agent/application/training/institute/life/application/get-list";
  static LIFE_TRAINING_INST_APPLICATION_DETAIL_GET_BY_OID =
    "/v1/agent/application/training/institute/life/application/get-by-oid";
  static LIFE_TRAINING_INST_CREATE_TRANING_BATCH =
    "/v1/agent/application/training/institute/batch/create";
  static LIFE_TRAINING_INST_TRANING_BATCH_LIST =
    "/v1/agent/application/training/institute/batch/get-list";
  static LIFE_TRAINING_INST_TRANING_BATCH_DETAIL_GET_BY_OID =
    "/v1/agent/application/training/institute/batch/get-by-oid";
  static LIFE_TRAINING_INST_GET_APPLICATION_LIST_FROM_BATCH =
    "/v1/agent/application/training/institute/batch/application/life/get-list";
  static LIFE_TRAINING_INST_ADD_OR_DISCARD_BATCH =
    "/v1/agent/application/training/institute/batch/life/add-or-discard";
  static TRAINING_INST_LIFE_BATCH_APPROVE =
    "/v1/agent/application/training/institute/batch/life/approve";
  static TRAINING_INST_LIFE_INDIVIDUAL_APPLICATION_APPROVE =
    "/v1/agent/application/training/institute/batch/life/application/approve";
  static LIFE_TRAINING_INST_APPLICATION_REVERT =
    "/v1/agent/application/training/institute/life/application/revert";
  static LIFE_TRAINING_INST_APPLICATION_REMOVE_FROM_TRAINING_LIST =
    "/v1/agent/application/training/institute/life/application/remove-from-training-list";
  static LIFE_TRAINING_INST_APPLICATION_UPDATE_INST_NAME =
    "/v1/agent/application/training/institute/life/application/update-inst-name";

  // --------------------------- Training Institute Portal (Non-life) -----------------------------

  static NON_LIFE_AGENT_TRAINING_INST_APPLICANT_LIST =
    "/v1/agent/application/non-life/training/applicant/get-list";
  static NON_LIFE_AGENT_TRAINING_INST_APPLICATION =
    "/v1/agent/application/non-life/training/training-application";
  static NON_LIFE_AGENT_TRAINING_INST_APPLICATION_LIST =
    "/v1/agent/application/non-life/training/application/get-list";
  static NON_LIFE_TRAINING_APPLICATION_LIST =
    "/v1/agent/application/training/institute/non-life/application/get-list";
  static NON_LIFE_TRAINING_INST_APPLICATION_DETAIL_GET_BY_OID =
    "/v1/agent/application/training/institute/non-life/application/get-by-oid";
  static TRAINING_INST_BATCH_NON_LIFE_ADD_OR_DISCARD =
    "/v1/agent/application/training/institute/batch/non-life/add-or-discard";
  static TRAINING_NON_LIFE_APPLICATION_LIST_BY_BATCH =
    "/v1/agent/application/training/institute/batch/application/non-life/get-list";
  static TRAINING_INST_NON_LIFE_BATCH_APPROVE =
    "/v1/agent/application/training/institute/batch/non-life/approve";
  static TRAINING_INST_NON_LIFE_INDIVIDUAL_APPLICATION_APPROVE =
    "/v1/agent/application/training/institute/batch/non-life/application/approve";
  static NON_LIFE_TRAINING_INST_APPLICATION_REVERT =
    "/v1/agent/application/training/institute/non-life/application/revert";
  static NON_LIFE_TRAINING_INST_APPLICATION_REMOVE_FROM_TRAINING_LIST =
    "/v1/agent/application/training/institute/non-life/application/remove-from-training-list";
  static NON_LIFE_TRAINING_INST_APPLICATION_UPDATE_INST_NAME =
    "/v1/agent/application/training/institute/non-life/application/update-inst-name";

  static PUBLIC_TRAINING_CERTIFICATE_REPORT = "/public/training/certificate";

  // --------------------------- Accounts Portal (Life) -----------------------------

  static TRAINING_ACCOUNTS_PORTAL_LIFE_BATCH_PAYMENT_UPDATE =
    "/v1/agent/application/training/institute/accounts/life/batch/update-payment";
  static TRAINING_ACCOUNTS_PORTAL_LIFE_APPLICATION_PAYMENT_UPDATE =
    "/v1/agent/application/training/institute/accounts/life/application/update-payment";

  // --------------------------- Accounts Portal (Non-life) -----------------------------

  static TRAINING_ACCOUNTS_PORTAL_NON_LIFE_BATCH_PAYMENT_UPDATE =
    "/v1/agent/application/training/institute/accounts/non-life/batch/update-payment";
  static TRAINING_ACCOUNTS_PORTAL_NON_LIFE_APPLICATION_PAYMENT_UPDATE =
    "/v1/agent/application/training/institute/accounts/non-life/application/update-payment";
  static LIFE_TRAINING_INST_APPLICATION_GET_BY_AGENT_CODE =
    "/v1/agent/application/training/institute/life/application/add-applicant-by-agent-code";
  static NON_LIFE_TRAINING_INST_APPLICATION_GET_BY_AGENT_CODE =
    "/v1/agent/application/training/institute/non-life/application/add-applicant-by-agent-code";

  // --------------------------- ALO EKYC Verification-----------------------------

  static ALO_EKYC_VERIFICATION =
    "/v1/agent/application/alo/agent-ekyc-verification";

  //------------------------IDRA Training institute report----------------------------

  static TOTAL_AGENT_TRAINING_INSTITUTE_SUMMARY =
    "/v1/report/module/agent/summary/training-institute";

  //------------------------Agent Summary report----------------------------

  static AGENT_SUMMARY_REPORT = "/v1/agent/summary/pdf";
  static AGENT_SUMMARY_COMPANY_WISE_REPORT = "/v1/agent/summary/company/pdf";
  static AGENT_SUMMARY_XLSX_DOWNLOAD_PATH = "/v1/agent/summary/xl";
  static AGENT_SUMMARY_COMPANY_WISE_REPORT_XLSX_DOWNLOAD_PATH =
    "/v1/agent/summary/company/xl";
  static AGENT_STATUS_REPORT = "/v1/agent/status/diagram/pdf";

  //------------------------EKYC Summary Report----------------------------

  static TOTAL_EKYC_SUMMARY = "/v1/report/module/ekyc/summary";
  static TOTAL_EKYC_SUMMARY_CSV_DOWNLOAD_PATH =
    "/v1/report/module/ekyc/get-csv";

  //------------------------Reference Signature ----------------------------

  static UPLOAD_REFERENCE_SIGNATURE_IMG_PATH =
    "/v1/system/reference-signature/upload";
  static ADD_REFERENCE_SIGNATURE_PATH = "/v1/system/add-reference-signature";
  static REFERENCE_SIGNATURE_LIST_PATH =
    "/v1/system/reference-signature/get-list";
  static REFERENCE_SIGNATURE_DETAILS_PATH =
    "/v1/system/reference-signature/get-details";
  static REFERENCE_SIGNATURE_DOWNLOAD_PATH =
    "/v1/system/reference-signature/download";
  static REFERENCE_SIGNATURE_DELETE_PATH =
    "/v1/system/reference-signature/delete";
  static UPDATE_REFERENCE_SIGNATURE_PATH =
    "/v1/system/reference-signature/update-status";
  static UPDATE_REFERENCE_INFO_PATH =
    "/v1/system/reference-signature/update-info";
}

export class AgentAPI {
  static SAVE_URL = "agent/application/v1/save";
  static EDIT_URL = "agent/application/v1/save-edit";
  static GET_LIST_URL = "agent/application/v1/get-list";
  static GET_BATCH_LIST_URL = "agent/batch/v1/get-list";
  static GET_ALL_BATCH_LIST_URL = "agent/batch/v1/get-all-list";
  static GET_OWNED_BATCH_LIST_URL = "agent/batch/v1/get-list-by-user";
  static GET_APPROVE_LIST_URL = "agent/batch/v1/get-approve-list";
  static GET_DETAIL_URL = "agent/application/v1/get-by-oid";
  static GET_BATCH_DETAIL_BY_OID_URL = "agent/batch/v1/get-by-oid";
  static UNLINK_URL = "agent/batch/v1/unlink";
  static DISCARD_BY_OIDS_URL = "agent/batch/v1/discard";
  static FILE_UPLOAD_URL = "agent/application/v1/upload";
  static GET_FILE_URL = "agent/application/v1/download";
  static GET_LOGO_URL = "agent/application/v1/get-logo";
  static APPLICATION_DISCARD_URL = "agent/application/v1/discard";
  static REMOVE_FILE_URL = "agent/application/v1/remove-file";
  static UNLINK_AGENT_APP_URL = "agent/application/v1/unlink";
  static UPDATE_AGENT_APP_URL = "agent/application/v1/update";
  static LINK_AGENT_APP_URL = "agent/application/v1/link";
  static UPDATE_BATCH_URL = "agent/batch/v1/update";
  static LOCK_URL = "agent/batch/v1/lock";
  static UNLOCK_URL = "agent/batch/v1/unlock";
  static UPDATE_ACK_REFERENCE_URL = "agent/batch/v1/update-ack-reference";
}
