import { MatPaginatorIntl } from "@angular/material/paginator";
import { convertToBnEn } from "@app/utility/constant/constant";

const banglaRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) { return convertToBnEn(`0 হইতে ${length}`); }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return convertToBnEn(`${startIndex + 1} - ${endIndex} হইতে ${length}`);
};


export function getBanglaPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = "প্রতি পৃষ্ঠায় আইটেম";
  paginatorIntl.nextPageLabel = "পরবর্তী পৃষ্ঠা";
  paginatorIntl.previousPageLabel = "পূর্ববর্তী পৃষ্ঠা";
  paginatorIntl.getRangeLabel = banglaRangeLabel;
  return paginatorIntl;
}
