import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API, APIConfig } from "@app/utility/constant/api-config";
import { AUTH_STORE_KEY } from "@app/utility/constant/constant";
import { PATH } from "@app/utility/constant/path";
import { AuthInfo, CompanyJson } from "@app/utility/model/model/auth.info";
import { AppStorageService } from "@app/utility/services/app.storage.service";
import { GlobalService } from "@app/utility/services/global.service";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs/internal/observable/throwError";

@Injectable({ providedIn: "root" })
export class AuthService {
  public userInfo = new AuthInfo();
  public companyJson = new CompanyJson();
  public contextPath: string;

  constructor(
        private appStorageService: AppStorageService,
        private restClient: HttpClient,
        private router: Router,
        private handler: HttpBackend,
        private globalService: GlobalService
  ) {
    this.restClient = new HttpClient(handler);
  }

  get isLoggedIn(): boolean {
    return !!this.getTokenInfo();
  }

  public getTokenInfo() {
    return JSON.parse(this.appStorageService.getData(AUTH_STORE_KEY));
  }

  public getAuthInfo() {
    this.globalService.getUserInfo().subscribe(info => {
      this.userInfo.loginId = info?.loginId;
      this.userInfo.roleId = info?.roleoid;
      this.userInfo.userName = info?.username;
      this.userInfo.companyOid = info?.companyOid;
      this.userInfo.companyType = info?.companyType;
      this.userInfo.resetRequired = info?.resetRequired;
    });
    return this.userInfo;
  }

  public login(userId: string, password: string): Observable<any> {
    const payload = {
      userid: userId,
      password: password,
    };
    return this.restClient.post(APIConfig.resourceServerUrl + API.SIGNIN, payload);
  }


  sessionTimeout() {
    this.appStorageService.clear();
    this.router.navigateByUrl("/login");
    return throwError("Your session has expired!, try again");
  }

  public clearCache(): void {
    try {
      this.appStorageService.clear();
      this.router.navigate([PATH.LOGIN]);
    } catch (e) {
      throw Error("Unable to logout!!!");
    }
  }

  public saveAuthInfo(value: any) {
    try {
      this.appStorageService.setData(AUTH_STORE_KEY, JSON.stringify(value));
    } catch (e) {
      throw Error("Unable to save from storage for Auth Information!!!");
    }
  }

  public getCompanyOid() {
    const userInfo = this.getAuthInfo();
    if (userInfo.companyOid) {
      return userInfo.companyOid;
    }
    return;
  }

  public getCompanyType() {
    const userInfo = this.getAuthInfo();
    if (userInfo.companyType) {
      return userInfo.companyType;
    }
    return;
  }
}
