import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API, APIConfig } from "@app/utility/constant/api-config";
import { Observable } from "rxjs";

import { APPLICATION_BY_AGENT, getHttpHeaders } from "@app/utility/constant/constant";

@Injectable({
  providedIn: "root"
})
export class SessionService {
  public redirectUrl: string;
  public logoutMessage: string;
  public hasSessionExpired = false;

  constructor(private router: Router, private http: HttpClient) { }


  revokeToken(): Observable<HttpResponse<any>> {

    return this.http.put(APIConfig.resourceServerUrl + API.SIGNOUT, {}, { headers: getHttpHeaders(), observe: "response" });
  }

  logout() {
    this.revokeToken().subscribe(response => {
      if (response.status === 200) {
        this.logoutV2();
      }
    },
    () => {
      this.logoutV2();
    },
    );
  }

  logoutV2() {
    // this.oAuth2Service.oAuth2.reset();
    localStorage.clear();
    this.logoutNavigate();
  }

  logoutNavigate() {
    if (this.hasSessionExpired) {
      this.router.navigate(["/session-expired"]);
    }
    else {
      this.router.navigate(["/signin"]).then(isNavigated => {
        if (isNavigated) {
          //
        }
      });
    }
  }

  goToPublicPage() {
    this.router.navigate([APPLICATION_BY_AGENT]);
  }
}
