import { Injectable, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root"
})
export class NotificationService {

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  addExtraClass = false;
  constructor(public snackBar: MatSnackBar, private zone: NgZone, private dialog: MatDialog) {

  }

  showSuccess(message: string): void {
    this.zone.run(() => {
      const config = new MatSnackBarConfig();
      config.horizontalPosition = this.horizontalPosition;
      config.verticalPosition = this.verticalPosition;
      config.panelClass = ["isa_success"];
      config.duration = 5000;
      this.snackBar.open(message, "x", config);
    });
  }

  showError(msg: any): void {
    // const msgup = msg?.error ? msg.message : msg;
    this.zone.run(() => {
      const config = new MatSnackBarConfig();
      config.horizontalPosition = this.horizontalPosition;
      config.verticalPosition = this.verticalPosition;
      config.panelClass = ["isa_error"];
      config.duration = 5000;
      this.snackBar.open(msg, "x", config);
    });
  }

  showInfo(msg: any): void {
    // const msgup = msg?.error ? msg.message : msg;
    this.zone.run(() => {
      const config = new MatSnackBarConfig();
      config.horizontalPosition = this.horizontalPosition;
      config.verticalPosition = this.verticalPosition;
      config.panelClass = ["isa_info"];
      config.duration = 5000;
      this.snackBar.open(msg, "x", config);
    });
  }

  showWarn(message: string): void {
    this.zone.run(() => {
      const config = new MatSnackBarConfig();
      config.horizontalPosition = this.horizontalPosition;
      config.verticalPosition = this.verticalPosition;
      config.panelClass = ["isa_warning"];
      config.duration = 5000;
      this.snackBar.open(message, "x", config);
    });
  }
}
