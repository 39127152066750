import { ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import { filter, map } from "rxjs/operators";
import { LoaderService } from "./utility/services/loader.service";
export let browserRefresh = false;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy, DoCheck {

  showLoader: boolean;
  private onDestroy = new Subject<void>();

  constructor(private loaderService: LoaderService, private cdr: ChangeDetectorRef, private router: Router,
                private updates: SwUpdate, private titleService: Title, private activatedRoute: ActivatedRoute) {
    router.events.pipe(takeUntil(this.onDestroy)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  reloadCache() {
    if (this.updates.isEnabled) {
      this.updates.available.subscribe(() => {
        if (confirm("New version available! would you like to update?")) {
          document.location.reload();
          // updates.activateUpdate().then(() => document.location.reload());
        }
      });
    }
  }

  ngOnInit() {
    this.reloadCache();
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public ngDoCheck(): void {
    this.cdr.detectChanges();
  }
}
