import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export const AUTH_STORE_KEY = "accesstoken.alo.cookie.store.key";
export const APPLICATION_NAME = "agent-license-online";
export const CLIENT_NAME = "alo";
export const REQUEST_VERSION = "1.0";
export const PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];
export const STATUS_ACTIVE = "Active";
export const POLICY = "Policy";
export const STATUS_SEND_SMS = "Sent";
export const mobileNoRegEx = "^(0|[0+]880)1[3-9][0-9]{8}$";
export const EmailRegEx = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$";
export const YES = "Yes";
export const NO = "No";
export const INVALID_TOKEN = "401";
export const passwordPolicyNotContainCharRegEx = /^(?![\^&%]*([\^&%])[\^&%]*\1)[\^&%]+$/;
export const TYPE_NON_LIFE = "Non-life";
export const TYPE_LIFE = "Life";
export const nidNoRegEx = "^([0-9]{10}|[0-9]{13}|[0-9]{17})$";
export const birthCertRegEx = "^([0-9]{17})$";
export const passportNoRegEx = "^([A-Z]{1,4}[0-9]{6,15})$";
export const birthDrivingNoRegEx = "^([0-9]{8,35})$";
export const birthCertOrPassportRegEx = "^([A-Z0-9]{6,20})$";
export const bankAccountNoRegEx = "^([A-Z0-9.-]{3,50})$";
export const mfsAccountNoRegEx = "^(0|[0+]880)1[3-9][0-9]{8,9}$";
// eslint-disable-next-line no-useless-escape
export const agentCodeOrReleaseLetterRegEx = "^[A-Z]{3,4}\/[0-9]{4}\/[A-Z0-9]{5,7}$";
// eslint-disable-next-line no-useless-escape
export const licenseNumberRegEx = "^[A-Z]{3,4}\/[4,6]{1}\/[0-9]{4}\/[A-Z0-9]{5,7}$";
export const AgentNameRegEx = "/^(?:[A-Za-z]{2,30} ?\b){1,10}$/gim";
export const CONTENT_TYPE = "application/json";
export const REQUEST_TIMEOUT_IN_SECONDS = "30";
export const LICENSE_APPLICATION_FEES = 1500;
export const RENEWAL_APPLICATION_FEES = 1000;
export const APPLICATION_BY_AGENT = "/form1";
export const FORM_FOUR = "4";
export const FORM_SIX = "6";

export function getHttpHeaders(): HttpHeaders {
  return new HttpHeaders()
    .set("content-type", CONTENT_TYPE)
    .set("accept", "application/json");
}

export function getFileUploadHeader(): HttpHeaders {
  if (environment.production) {
    return new HttpHeaders({ "ngsw-bypass": "true" });
  }
  return new HttpHeaders();
    
}

export function convertToBnEn(str: string) {
  if (!str) {
    return "";
  }
  let newStr = "";
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < str.length; i++) {
    switch (str[i]) {
    case "0":
      newStr += "০";
      break;
    case "1":
      newStr += "১";
      break;
    case "2":
      newStr += "২";
      break;
    case "3":
      newStr += "৩";
      break;
    case "4":
      newStr += "৪";
      break;
    case "5":
      newStr += "৫";
      break;
    case "6":
      newStr += "৬";
      break;
    case "7":
      newStr += "৭";
      break;
    case "8":
      newStr += "৮";
      break;
    case "9":
      newStr += "৯";
      break;
    default:
      newStr += str[i];
      break;
    }
  }
  return newStr.toString();
}
