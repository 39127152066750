import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { LoaderService } from "@app/utility/services/loader.service";
import { NotificationService } from "@app/utility/services/notification.service";
import { LoggingService } from "./logging.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    [x: string]: any;

    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {
      const logger = this.injector.get(LoggingService);
      const loaderService = this.injector.get(LoaderService);
      const notifier = this.injector.get(NotificationService);
      let message: string;

      if (error instanceof HttpErrorResponse) {
        // Server error
        if (error.status === 0 || error.status >= 500) {
          notifier.showError("অনুগ্রহপূর্বক একটু পর আবার চেষ্টা করুন, ধন্যবাদ");
        } else if (error.status === 401) {
          notifier.showError("অনুগ্রহপূর্বক আপনার ইউজার আইডি এবং পাসওয়ার্ড নিশ্চিত করুন");
        } else {
          message = error.message ? error.message : error.toString();
          notifier.showError(message);
        }
      } else {
        // Client Error
        message = navigator.onLine ? error.message : "অনুগ্রহ করে ইন্টারনেট সংযোগ যাচাই করুন";
        notifier.showError(message);
      }
      loaderService.display(false);
      // Always log errors
      logger.logError(error);
    }
}
