import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { of } from "rxjs/internal/observable/of";
import { timer } from "rxjs/internal/observable/timer";
import { flatMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AppCustomPreloader implements PreloadingStrategy {
//   preload(route: Route, load: Function): Observable<any> {
//     return route.data && route.data.preload ? load() : of(null);
//   }
  preload(route: Route, load: any): Observable<any> {
    const loadRoute = (delay) => delay
      ? timer(10000).pipe(flatMap(load()))
      : load();
    return route.data && route.data.preload
      ? loadRoute(route.data.delay)
      : of(null);
  }
}
