/**
 * this module is intended to be included only required componants from angular material library
 */
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { getBanglaPaginatorIntl } from "@app/utility/constant/bangla-paginator-intl";

// add only required modules
@NgModule({
  imports: [MatTableModule, MatPaginatorModule, MatSortModule, MatInputModule, MatCheckboxModule, MatTooltipModule,
    MatToolbarModule, MatIconModule, MatFormFieldModule, MatCardModule, MatButtonModule,
    MatDividerModule, MatSelectModule, MatDialogModule, MatProgressBarModule, MatSlideToggleModule,
    MatSnackBarModule, MatButtonToggleModule, MatRadioModule, MatTabsModule, MatProgressSpinnerModule, MatDatepickerModule],
  exports: [MatTableModule, MatPaginatorModule, MatSortModule, MatInputModule, MatCheckboxModule, MatTooltipModule,
    MatToolbarModule, MatIconModule, MatFormFieldModule, MatCardModule, MatButtonModule,
    MatDividerModule, MatSelectModule, MatDialogModule, MatProgressBarModule, MatSlideToggleModule,
    MatSnackBarModule, MatButtonToggleModule, MatRadioModule, MatTabsModule, MatProgressSpinnerModule, MatDatepickerModule],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBanglaPaginatorIntl() }
  ]
})
export class CustomMaterialModule { }
