import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/do";
import "rxjs/add/operator/mergeMap";

import { APPLICATION_BY_AGENT, AUTH_STORE_KEY } from "@app/utility/constant/constant";
import { SessionService } from "./session.service";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const sessionService = this.injector.get(SessionService);

    let accessToken = null;
    const authInfo = JSON.parse(localStorage.getItem(AUTH_STORE_KEY));
    if (localStorage.getItem(AUTH_STORE_KEY) && authInfo.access_token) {
      accessToken = authInfo.access_token;
    }
    if (this.isInvalidToken(accessToken)) {
      this.logoutV2(sessionService, "You are not logged in");
      return throwError({ error: "Access token not found" });
    }
    return next.handle(this.customRequest(request, accessToken)).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event;
        }
      })
      .catch((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            const refreshToken = authInfo.refresh_token;
            if (this.isInvalidToken(refreshToken)) {
              this.logoutV2(sessionService, "You are not logged in");
              return throwError({ error: "Refresh token not found" });
            }
            // return sessionService.renewToken().mergeMap(_ => {

            //     authInfo = JSON.parse(localStorage.getItem(AUTH_STORE_KEY));

            //     const accToken = authInfo.accessToken?.value;
            //     if (this.isInvalidToken(accToken)) {
            //         this.logoutV2(sessionService, 'You are not logged in');
            //         return throwError({ error: 'Access token not found' });
            //     }
            //     return next.handle(this.customRequest(request, accToken)).do((evn: HttpEvent<any>) => {
            //         if (evn instanceof HttpResponse) {
            //             return evn;
            //         }
            //     }).catch((er: any) => {
            //         er.loggedIn = true;
            //         return throwError(er);
            //     });
            // }).catch((err: any) => {
            //     if (err.loggedIn !== true) {
            //         this.logoutV2(sessionService, 'Your session has expired. Please login again', true);
            //     }
            //     return throwError(err);
            // });
          }
        }
        return throwError(error);
      });
  }

  isInvalidToken(token: string) {
    return (typeof token === "undefined" || token === null || token.toString().length === 0);
  }

  customRequest(request: HttpRequest<any>, accessToken: string) {
    const req = request.clone({
      setHeaders: {
        Authorization: "Bearer {0}".replace("{0}", accessToken)
      }
    });
    return req;
  }

  logoutV2(sessionService: SessionService, logoutMessage: string, hasSessionExpired = false) {
    const router = this.injector.get(Router);
    sessionService.redirectUrl = router.url;
    sessionService.logoutMessage = logoutMessage;
    sessionService.hasSessionExpired = hasSessionExpired;
    if (router.url === APPLICATION_BY_AGENT) {
      sessionService.goToPublicPage();
    } else {
      sessionService.logoutV2();
    }
  }
}

