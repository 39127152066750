export const PATH = {
  OTHER: "**",
  LOGIN: "signin",
  DASHBOARD: "dashboard",
  HOME: "home",
  CHANGE_PASSWORD: "change-password",
  PAGE_NOT_FOUND: "page-not-found"


};
