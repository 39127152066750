import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TokenInterceptorService } from "./common/services/token-interceptor.service";
import { SessionTimeOutSnackBarComponent } from "./core/dialog/session-timeout-snackbar.component";
import { SessionTimeOutComponent } from "./core/dialog/session-timeout.component";
import { GlobalErrorHandler } from "./core/services/global-error-handler";
import { CustomMaterialModule } from "./shared/custom-material.module";

export function createTranslateLoader(http: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(http), "./assets/i18n/", ".json");
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CustomMaterialModule,
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpBackend] } }),
    AppRoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production })

  ],
  declarations: [AppComponent, SessionTimeOutSnackBarComponent, SessionTimeOutComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    TranslateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
