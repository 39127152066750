import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppStorageService } from "@app/utility/services/app.storage.service";
import { PATH } from "@app/utility/constant/path";

@Component({
  selector: "app-session-timeout",
  template: `<div class="flex">
                    <div class="modal-header">
                        <h4 class="modal-title text-danger">{{ title }}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <p class="p-message">Session Timeout : <span class="text-muted">{{ message }}</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button mat-flat-button (click)="onCancelClick()" style="float: right">Sign in</button>
                    </div>
                </div>`,
})
export class SessionTimeOutComponent {

  message: string;
  title: string;

  constructor(private router: Router, public dialogRef: MatDialogRef<SessionTimeOutComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
                private appStorageService: AppStorageService) {
    this.title = data.title;
    this.message = data.message;
  }

  onCancelClick(): void {
    this.dialogRef.close();
    this.appStorageService.clear();
    this.router.navigate([PATH.LOGIN]);
  }
}
